import {
  BooleanSendRequirementTypeEnum,
  CancelReasonEnum,
  DispatchingAlertArchivedJustificationEnum,
  OrdreDeTravailPostponementReasonEnum,
} from '../Models/models';

const postponementReason = {
  [OrdreDeTravailPostponementReasonEnum.ClientEquipmentBreakdown]: "Client: Bris d'équipement",
  [OrdreDeTravailPostponementReasonEnum.ClientMechanicalBreakdown]: 'Client: Bris mécanique',
  [OrdreDeTravailPostponementReasonEnum.ClientUnknownReason]: 'Client: Sans connaître la raison',
  [OrdreDeTravailPostponementReasonEnum.ClientRepresentativeError]: 'Client: Erreur représentant',
  [OrdreDeTravailPostponementReasonEnum.ClientEquipmentShortage]: "Client: Manque d'équipement",
  [OrdreDeTravailPostponementReasonEnum.ClientLaborShortage]: "Client: Manque de main-d'oeuvre",
  [OrdreDeTravailPostponementReasonEnum.ClientCraneShortage]: 'Client: Manque de grue',
  [OrdreDeTravailPostponementReasonEnum.ClientIncorrectDate]: 'Client: Mauvaise date',
  [OrdreDeTravailPostponementReasonEnum.ClientWrongCrane]: 'Client: Mauvaise grue',
  [OrdreDeTravailPostponementReasonEnum.ClientDidNotReceiveOccupancyPermit]: "Client: N'a pas eu son permis d'occupation (MTL)",
  [OrdreDeTravailPostponementReasonEnum.ClientWeather]: 'Client: Température',
  [OrdreDeTravailPostponementReasonEnum.ClientTransportation]: 'Client: Transport',
  [OrdreDeTravailPostponementReasonEnum.GuayEquipmentBreakdown]: "Guay: Bris d'équipement",
  [OrdreDeTravailPostponementReasonEnum.GuayEquipmentShortage]: "Guay: Manque d'équipement",
  [OrdreDeTravailPostponementReasonEnum.GuayLaborShortage]: "Guay: Manque de main-d'oeuvre",
};

const cancelReason = {
  [CancelReasonEnum.ClientEquipmentBreakdown]: "Client: Bris d'équipement",
  [CancelReasonEnum.ClientMechanicalBreakdown]: 'Client: Bris mécanique',
  [CancelReasonEnum.ClientUnknownReason]: 'Client: Sans connaître la raison',
  [CancelReasonEnum.ClientRepresentativeError]: 'Client: Erreur représentant',
  [CancelReasonEnum.ClientEquipmentShortage]: "Client: Manque d'équipement",
  [CancelReasonEnum.ClientLaborShortage]: "Client: Manque de main-d'oeuvre",
  [CancelReasonEnum.ClientCraneShortage]: 'Client: Manque de grue',
  [CancelReasonEnum.ClientIncorrectDate]: 'Client: Mauvaise date',
  [CancelReasonEnum.ClientWrongCrane]: 'Client: Mauvaise grue',
  [CancelReasonEnum.ClientDidNotReceiveOccupancyPermit]: "Client: N'a pas eu son permis d'occupation (MTL)",
  [CancelReasonEnum.ClientWeather]: 'Client: Température',
  [CancelReasonEnum.ClientTransportation]: 'Client: Transport',
  [CancelReasonEnum.GuayEquipmentBreakdown]: "Guay: Bris d'équipement",
  [CancelReasonEnum.GuayEquipmentShortage]: "Guay: Manque d'équipement",
  [CancelReasonEnum.GuayLaborShortage]: "Guay: Manque de main-d'oeuvre",
};

const sendRequirementTypes = {
  [BooleanSendRequirementTypeEnum.ContinuityConfirmed]: "Confirmation de l'OT",
  [BooleanSendRequirementTypeEnum.ServiceCallStatus]: 'Appel de service transféré',
  [BooleanSendRequirementTypeEnum.AccessoryIdentification]: "Identification d'accessoires",
};

const alert = {
  alerts: 'alertes',
  alertCenter: "Centre d'alertes",
  active: 'Non-traitées',
  archived: 'Archivées',
  archive: 'Archiver',
  archivedBy: 'Archivée par',
  high: 'Majeures',
  low: 'Mineures',
  see: 'Voir',
  commentPlaceholder: 'Entrez un commentaire...',
  trainingJustifications: {
    [DispatchingAlertArchivedJustificationEnum.TrainingWillBeCompleted]: "La formation va être suivie avant le début de l'ordre de travail",
    [DispatchingAlertArchivedJustificationEnum.TrainingWasCompleted]: 'Déjà formé, pas entré au système',
    [DispatchingAlertArchivedJustificationEnum.TrainingNotRequired]: "La formation n'est pas requise",
    [DispatchingAlertArchivedJustificationEnum.EmployeeAccompanied]: "L'employé va être accompagné",
  },
  craneCapacityBelowJustifications: {
    [DispatchingAlertArchivedJustificationEnum.TonnageNotInContract]: "Le tonnage demandé n'est pas inclus dans le contrat annuel",
    [DispatchingAlertArchivedJustificationEnum.InterchangeableEquipment]: 'Les équipements sont interchangeables',
    [DispatchingAlertArchivedJustificationEnum.EquipmentMeetsNeed]:
      "Tonnage requis non disponible, mais l'équipement choisi répond au besoin",
  },
};

const transports = {
  transports: 'Transports',
  planned: 'Planifié',
  unplanned: 'À Planifier',
  description: 'Description',
  supplier: 'Fournisseur',
  numeroDeCommandeAchat: '#CA',
  departure: 'Départ',
  plan: 'Planifier',
  isRequired: '{{field}} est requis.',
};

const incompatibility = {
  incompatibilities: 'Incompatibilités',
};

export const dispatch = {
  alert,
  transports,
  incompatibility,
  accessory_one: '{{count}} Accessoire',
  accessory_other: '{{count}} Accessoires',
  all: 'Tout',
  arrival: 'Arrivée',
  arrivalTime: "Heure d'arrivée",
  bare: 'Location Non-Opérée',
  remark: 'Remarque',
  description: 'Description',
  supplier: 'Fournisseur',
  assignation: 'Assignation',
  branches: 'Succursales',
  client: 'Client',
  confirmSendAssignments: 'Confirmer les assignations à envoyer',
  craneNumber: "Numéro d'unité",
  departure: 'Départ',
  departureTime: 'Heure départ',
  ready: 'Prêt',
  readyTime: 'Heure prêt',
  requestModification: 'Demande de modification',
  estimatedDurationInHours: {
    label: 'Durée des travaux',
    shortLabel: 'Durée',
    value_one: '{{count}} heure',
    value_other: '{{count}} heures',
  },
  filters: 'Filtres',
  hideEmptyResource: 'Masquer les lignes vides',
  hideUnchangedAssignments: 'Masquer les assignations non-modifiées',
  maximumWeightToLift: {
    label: 'Poids de la charge',
    value_one: '{{count}} lb',
    value_other: '{{count}} lbs',
  },
  noBranchSelected: 'Aucune succursale sélectionnée',
  only: 'Seulement',
  optimizationCanceled: "Optimisation annulée (5 minutes d'écoulées)",
  optimizationStarted: 'Optimisation commencée',
  optimize: 'Optimiser',
  permissionsOnAllBranches_send: 'Vous devez avoir les droits en édition sur toutes les succursales sélectionnées pour envoyer les OTs',
  permissionsOnAllBranches_optimize:
    'Vous devez avoir les droits en édition sur toutes les succursales sélectionnées pour optimiser les OTs',
  cancelOptimization: "Annuler l'optimisation",
  ongoingOptimization: 'Optimisation en cours',
  applyingOptimization: 'Application de l`optimisation',
  optimizationAppliedMessage: 'Les ordres de travail et leur continuité ont été mis a jour',
  selectedBranch: 'Succursale sélectionnée',
  selectedBranchTooltip: 'Sélectionner une succursale seulement',
  interBranch: 'Inter-succursales',
  exportDailyAccessoriesReport: "Exporter la fiche journalière d'accessoires",
  exportDailyCraneAndAccessoriesReport: 'Exporter la fiche journalière de grues et accessoires',
  exportDailyAccessoriesReportTooltip: 'Disponible uniquement si une seule branche est sélectionnée',
  representative: 'Chargé de projet',
  requiredCapacity: {
    label: 'Capacité requise',
    shortLabel: 'Capacité',
    value_one: '{{count}} tonne',
    value_other: '{{count}} tonnes',
    shortValue: '{{count}} T',
  },
  resetFilters: 'Effacer les filtres',
  operatorCompetence: {
    pasUnGrutier: 'Pas un grutier',
    compagnon: 'Compagnon',
    apprenti1: 'Apprenti - 1',
    apprenti2: 'Apprenti - 2',
    apprenti3: 'Apprenti - 3',
    exception: 'Exception',
  },
  ordresDeTravail: 'Ordres de travail',
  craneOperators: 'Grutiers',
  operator: 'Grutier',
  cranes: 'Grues',
  crane: 'Grue',
  departureDate: 'Date de départ',
  newValue: 'Nouvelle valeur',
  oldValue: 'Ancienne valeur',
  resources: 'Ressources',
  subcontracted: 'Sous-Traitance',
  various: 'Divers',
  status: 'Statut',
  requiredConfiguration: 'Configuration requise',
  selectAll: 'Tout sélectionner',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  modified: 'Modifier',
  loading: 'Chargement',
  send: 'Envoyer',
  transferOrdreDeTravail: 'Transférer de succursale',
  selectNewUnit: 'Veuillez sélectionner l’unité à assigner',
  branch: 'Succursale',
  unit: 'Unité',
  branchInOptimization: 'La succursale est présentement en optimisation. Un transfert est impossible.',
  subcontract: 'Envoyer en sous-traitance',
  selectSubcontractor: 'Veuillez saisir le nom du fournisseur et une description',
  subcontractor: 'Sous-traitant',
  numeroDeCommandeAchat: '#CA',
  transfer: 'Transférer',
  postpone: 'Reporter',
  accept: 'Accepter',
  refuse: 'Refuser',
  postponeOrdreDeTravail: 'Reporter l’OT',
  chooseNewWorkingDay: 'Veuillez sélectionner la nouvelle journée des travaux',
  date: 'Date',
  warnings: 'Avertissements',
  missingTrainingRequirements: 'Exigences formations manquantes',
  ordreDeTravailLinkPrefix: 'OT',
  workSite: {
    name: 'Chantier',
    location: 'Adresse du chantier',
  },
  dragDropErrors: {
    noStartDate: 'L’OT sélectionné n’a pas de date de départ',
    noServiceCallId: 'L’OT sélectionné provient de l’AS400 et ne peut pas être modifié',
    maxedCraneOperators: 'La quantité maximale de grutiers assignés est atteinte',
    craneOperatorAlreadyAssigned: 'Le grutier est déjà assigné à cet OT',
    employeeAlreadyAssigned: "L'employé est déjà assigné à cet OT",
    otAlreadyHasCrane: "L’OT sélectionné possède déjà une grue d'assignée",
    invalidResource: 'Ressource non valide',
    subcontracted: 'L’OT sélectionné est en sous-traitance et ne peut pas être modifié',
    invalidBranch: "L'OT ne peut pas être déplacé vers une autre succursale",
    unauthorized: "L'utilisateur n'est pas autorisé à modifier cette ressource",
    ongoing: "L'OT sélectionné est en cours",
    finished: "L'OT sélectionné est terminé",
    notRollingEquipment: 'L’OT sélectionné peut uniquement être assigné à un matériel roulant',
    cannotBeAssignedToRollingEquipment: 'L’OT sélectionné ne peut pas être assigné à un matériel roulant',
    cannotAssignToVarious: 'L’OT sélectionné ne peut pas être assigné à divers',
    notOperatorAssignable: 'Un opérateur ne peut être assigné à cet OT',
    notEquipmentAssignable: 'Un equipment ne peut être assigné à cet OT',
  },
  eventContextMenu: {
    all: 'Tout',
    advance: 'Devancer',
    add: 'Ajouter',
    edit: 'Modifications',
    editAccessories: 'Modifier les accessoires',
    locking: 'Verrouillage',
    lockAll: 'Tout verrouiller',
    unlockAll: 'Tout déverrouiller',
    unassign: "Retirer l'assignation",
    postpone: 'Reporter',
    transfer: 'Transférer de succursale',
    cancel: "Annuler l'OT",
    remove: 'Retirer',
    continuity: 'Continuité',
    subcontract: 'Envoyer en sous-traitance',
    removeSubcontractor: 'Retirer la sous-traitance',
    replaceOperator: 'Replacer grutier principal',
    sendToVarious: 'Envoyer vers divers',
    generateOtNumber: "Générer un numéro d'OT",
    viewOT: "Voir l'OT",
    modifyPlanification: 'Modifier la planification',
    requestModification: 'Retourner pour modification',
  },
  cancelOrdreDeTravailModal: {
    title: "Annuler l'OT",
    instruction: 'Veuillez entrer une raison.',
    label: 'Remarque',
    close: 'Annuler',
    confirm: "Annuler l'OT",
  },
  otGridCellContextMenu: {
    cancel: 'Annuler',
  },
  modifiedContinuity: 'Modifier une continuité',
  onlyThisContinuity: 'Cette continuité seulement',
  allContinuity: 'Toutes les continuités suivantes',
  unassignedOT: {
    title: 'OT non assignés',
    value_one: '{{count}} OT non assigné',
    value_other: '{{count}} OT non assignés',
  },
  optimizedSolution: 'Solution optimisée',
  optimizing: 'En optimisation',
  validation: {
    required: '*Requis',
    arrivalTimeBeforeDepartureTime: "Le temps d'arrivé ne peut pas être avant le temps de départ",
    readyTimeBeforeArrivalTime: "La temps prêt ne peut pas être avant le temps d'arrivé",
  },
  addToOTContinuity: 'Ajouter une continuité à l’OT',
  newContinuityMessage: 'Une nouvelle continuité sera ajoutée pour la période sélectionnée.',
  validateTimesTitle: 'Valider les heures de la continuité',
  from: 'De',
  to: 'À',
  addContinuity: 'Ajouter la continuité',
  accessoriesModal: {
    assignedTo: 'Assigné à',
    notAssignedWarning: "Lorsque ce champ est vide, l'accessoire est considéré comme assigné au grutier principal",
    title: 'Modifier les accessoires',
    accessoryType: "Type d'accessoires",
    identification: 'Identification',
    accessoryGroup: 'Accessoire',
    billing: 'Facturé',
    quantity: 'Quantité',
    billable: 'Facturable',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    select: 'Sélectionner',
    addRemark: 'Ajouter une remarque',
    hourly: "À l'heure",
    daily: 'Au jour',
    weekly: 'À la semaine',
    monthly: 'Au mois',
  },
  replaceOperatorModal: {
    title: 'Replacer le grutier principal',
    operator: 'Grutier',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    select: 'Sélectionner',
  },
  preferredOperatorsModal: {
    title: 'Modifier les grutiers attitrés',
    operators: 'Grutiers',
    cancel: 'Annuler',
    save: 'Sauvegarder',
    select: 'Sélectionner',
  },
  advanceOTModal: {
    title: "Devancer l'OT",
    content: 'Veuillez sélectionner la nouvelle journée des travaux',
    cancel: 'Annuler',
    advance: 'Devancer',
  },
  assignOperatorModal: {
    title: 'Assigner {{operator}}',
    cancel: 'Annuler',
    assign: 'Assigner',
    kind: 'Rôle',
    kindLabel: 'Veuillez sélectionner un rôle pour la ressource',
    assignInfoLabel: 'Informations d’assignation',
    startingPointLabel: 'Point de départ',
    branchStartingPoint: 'Succursale',
    siteStartingPoint: 'Chantier',
    departureTime: 'Heure de départ',
    arrivalTime: "Heure d'arrivée",
    readyDateTime: 'Heure prêt',
    updateContinuity: 'Assigner pour toute la continuité',
  },
  unavailabilitiesModal: {
    title: 'Éditer les indisponibilités',
    from: 'De',
    to: 'À',
    type: 'Type',
    comment: 'Commentaire',
    commentPlaceholder: 'Entrer un commentaire...',
    select: 'Sélectionner',
    options: {
      permanentOnWorkSite: 'Sur chantier',
      repairs: 'Garage',
      rented: 'Location non opérée',
    },
    cancel: 'Annuler',
    confirm: 'Confirmer',
    save: 'Sauvegarder',
    errors: {
      required: 'Requis',
      endBeforeStart: 'Ne peut pas finir avant le début',
    },
    readonlyTitle: 'Indisponibilités',
    close: 'Fermer',
    noUnavailabilities: 'Aucune indisponibilité enregistrée',
  },
  noSubcontractingModal: {
    title: 'Aucun sous-traitant accepté',
    content: "Le représentant n'a pas accepté de sous-traitant pour cet appel de service. Désirez-vous continuer?",
    cancel: 'Annuler',
    continue: 'Continuer',
  },
  lockingJustificationModal: {
    title: 'Justifier le verrou',
    content: 'Veuillez entrer une justification pour verrouiller l’assignation',
    justification: 'Justification',
    cancel: 'Annuler',
    lock: 'Verrouiller',
  },
  noSubcontracting: 'Aucun sous-traitant',
  noReplacement: 'Aucun remplacement de grue',
  secondServing: 'Deuxième tablée',
  readonly: 'Lecture',
  family: 'Famille',
  boomLength: 'Flèche',
  jibLength: 'Fléchette',
  counterweight: 'Contrepoids',
  offset: 'Offset',
  others: 'Autres',
  unavailabilityPeriod: 'Période',
  unavailabilityType: 'Type',
  permanentOnWorkSite: 'Sur chantier',
  repairs: 'Garage',
  rented: 'Location non opérée',
  unavailabilityComment: 'Commentaire',
  unavailability: 'Indisponibilité',
  greatCircleDistance: "Distance à vol d'oiseau: {{distance}}km",
  routedDistance: 'Distance routière avec trafic: {{distance}}km',
  branchEquipment: 'Succursale Équipement',
  unitNumber: 'No Unité',
  otNumber: 'No OT',
  craneOperatorName: 'Nom du grutier',
  serviceCallType: "Type d'appel",
  pageNotFound: 'La page est introuvable.',
  confirmDepartureTimeChangeModal: {
    title: 'Modification de l’heure de départ',
    message: "Voulez-vous modifier l'heure de départ de l'OT pour {{newTime}} (préalablement {{oldTime}})?",
  },
  possibleWrongDepartureDateTime: 'La date de départ pourrait être erronée.',
  comment: 'Commentaire',
  requiredPostponementReason: 'Commentaire est requis',
  postponedOT: 'OT reportés',
  postponement: {
    value_one: '{{count}} Report',
    value_other: '{{count}} Reports',
  },
  sendRequirements: 'Requis pour envoi',
  sendRequirementTypes,
  postponementReason,
  cancelReason,
  unmetRequirements: {
    requirements: 'Requis pour envoi:',
    continuityConfirmed: "confirmation de l'OT",
    isAssignedToVarious: 'envoi vers divers',
    hasMainEquipment: "assignation d'équipement",
    hasOperatorOrSubcontractor: 'assignation de grutier ou de sous-traitant',
    hasCraneOperator: 'assignation de grutier',
    serviceCallStatus: 'appel de service réservé',
    operatorKind: "assignation d'un {{kind}}",
    alert: "résolution d'alertes",
    departureDateTime: 'heure de départ manquante',
    accessoryIdentification: "identification d'accessoire",
  },
  confirmOT: 'Confirmer',
  confirmOrdreDeTravailModal: {
    title: "Confirmer l'ordre de travail",
    keepOT: 'Garder # OT',
    generateOT: 'Générer un nouveau # OT',
    otNumberLabel: "Numéro d'ordre de travail",
    dateRange: 'Plage de dates',
    today: 'Journée active',
    personalizedDate: 'Date personnalisée',
    numberAutomaticallyGenerated:
      "Un nouveau numéro d'OT sera généré. Vous allez potentiellement perdre un numéro d'OT existant. Est-ce que vous voulez continuer ?",
  },
  assignmentContinuityModal: {
    title: "Retirer un requis d'assignation",
    allExceptLast: 'Tout sauf la dernière journée',
    remove: 'Retirer',
  },
};
